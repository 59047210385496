<template>
  <div class="text-center" v-if="loading">
    <h3>Please wait...</h3>
  </div>
  <div class="container mx-auto" v-if="product">
    <div class="lg:hidden p-3">
      <h1 class="text-3xl !leading-tight line-clamp-3">{{ product.title }}</h1>

      <img class="h-64 w-full object-contain mt-3"
           :src="slider.img"
           :alt="product.title">

      <div class="overflow-x-auto flex flex-nowrap mt-5">
        <img class="w-[64px] h-[64px] mr-2"
             v-for="(img, i) in product.images"
             :key="'img_'+i"
             @click="changeImage(i, img)"
             :src="img"
             :alt="product.title">
      </div>

      <div class="flex items-center flex-col mt-3">
        <h3 class="text-3xl lg:text-6xl text-yellow-500 italic" v-if="product.price.current_price">${{ product.price.current_price }}</h3>
        <h3 class="text-xl text-yellow-500 italic" v-else>Currently unavailable.</h3>
        <div class="flex-1"></div>
        <a target="_blank"
           :href="amazonLink">
          <img src="/buyonamazon.png" class="w-60 mt-1 lg:mt-0" alt="" />
        </a>
      </div>

      <h3 class="text-xl lg:text-3xl mt-8">About the product</h3>
      <ul class="list-disc mt-4 lg:mt-8 list-outside pl-5">
        <li class="text-base lg:text-xl text-slate-700 mb-3"
            v-for="(bullet, i) in product.feature_bullets" :key="'bullet_'+i">
          {{ bullet }}
        </li>
      </ul>
      <img
          class="w-40 mt-12 mx-auto lg:mx-0"
          src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg"
          alt=""
      />
    </div>
    <div class="hidden md:block">
      <div class="flex">
        <div class="flex-1 p-8">
          <img :src="slider.img"
               :alt="product.title"
               id="image-preview"
               class="h-96  w-full object-contain">

          <div class="overflow-x-auto flex flex-nowrap mt-5">
            <img class="w-[64px] h-[64px] mr-2"
                 v-for="(img, i) in product.images"
                 :key="'img_'+i"
                 @click="changeImage(i, img)"
                 :src="img"
                 :alt="product.title">
          </div>
        </div>
        <div class="flex-1 p-8">
          <h1 class="font-bold text-3xl leading-tight hidden lg:flex line-clamp-3">
            {{ product.title }}
          </h1>

          <div class="flex items-center lg:mt-8 flex-col lg:flex-row">
            <h3 class="text-3xl lg:text-6xl text-yellow-500 italic" v-if="product.price.current_price">${{ product.price.current_price }}</h3>
            <h3 class="text-xl text-yellow-500 italic" v-else>Currently unavailable.</h3>
            <div class="flex-1"></div>
            <a target="_blank"
               :href="amazonLink">
              <img src="/buyonamazon.png" class="w-60 mt-1 lg:mt-0" alt="" />
            </a>
          </div>
          <h3 class="text-xl lg:text-3xl mt-8">About the product</h3>
          <ul class="list-disc mt-4 lg:mt-8 list-outside pl-5">
            <li class="text-base lg:text-xl text-slate-700 mb-3"
                v-for="(bullet, i) in product.feature_bullets" :key="'bullet_'+i">
              {{ bullet }}
            </li>
          </ul>

          <img
              class="w-40 mt-12 mx-auto lg:mx-0"
              src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg"
              alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createDeviceDetector } from "next-vue-device-detector";
export default {
  data() {
    return {
      loading: false,
      amazonAd: null,
      product: null,
      slider: {
        img: null,
        currentIndex: 0
      }
    }
  },
  watch: {
    productTitle() {
      document.title = this.productTitle;
    }
  },
  computed: {
    productTitle() {
      if (this.amazonAd && this.amazonAd.product_title) {
        return this.amazonAd.product_title
      } else if (this.product) {
        return this.product.title;
      } else {
        return 'Attribution Insights';
      }
    },
    amazonLink() {
      if (this.amazonAd) {
        let url = this.amazonAd.influencer_campaign_full_url;

        if (this.$route.query.tag)
          url += '&tag=' + this.$route.query.tag;

        const device = createDeviceDetector()

        if (device.mobile) {
          url = url.replace("https://" + this.amazonAd.influencer_amazon_host, "com.amazon.mobile.shopping.web://amazon.com")
        }

        return url;
      }

      return '#';
    }
  },
  created() {
    this.loadProduct();
    this.loadAmazonAd();

  },
  methods: {
    changeImage(i, img) {
      this.slider.currentIndex = i;
      this.slider.img = img;
    },
    loadAmazonAd() {
      this.axios.get('/amazon-ad/' + this.$route.params.id)
          .then(res => {
            this.amazonAd = res.data.data;
          })
    },
    loadProduct() {
      this.loading = true;

      this.axios.get('/product-details/' + this.$route.params.id)
          .then(res => {
            this.product = res.data.data;

            this.slider.img = this.product.images[0];
          })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>